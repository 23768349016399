[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.table > tbody > tr.bold > td {
  font-weight: bold;
}

.label-lg {
  font-size: 120%;
  padding: 0.8em 1.6em;
}
